


























































































import { Vue, Component } from 'vue-property-decorator'
import DownloadAssets from './DownloadAssets.vue'
import playBtn from './Mobile.vue'

const iosApp: Release.Asset = {
    size: 0,
    arch: 'arm64',
    url: '',
    platform: 'ios',
    fileName: 'Sync2app',
    checksum: ''
  }

@Component({
    components: {
        DownloadAssets,
        playBtn
    }
})
export default class SyncPage extends Vue {
    show = false

    get androidUrl() {
      const a = this.assets.find(i => i.platform === 'android')

      if (a) {
        return a.url
      } else {
        return ''
      }
    }
    
    get preferredAsset() {
        return this.$env.preferredAsset(this.$env.syncReleases[0].assets)
    }

    get assets() {
      this.$env.syncReleases[0].assets.push({
        ...iosApp,
        url: this.$env.AppStore
      })
      return this.$env.syncReleases[0].assets
    }
}
