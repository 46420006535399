









































import { Vue, Component } from 'vue-property-decorator'
import SyncPage from './SyncPage.vue'

@Component({
    components: {
        SyncPage
    }
})
export default class Home extends Vue {
    private hash = location.hash
    private showMenu = false

    private created() {
        this.$ga.page('/sync.vecha.in/home')
    }

    private switchTab(hash: string) {
        location.href = hash
        this.hash = hash
    }
}
