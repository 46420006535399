



























import { Vue, Component, Prop, Emit } from 'vue-property-decorator'

@Component
export default class DownloadAssets extends Vue {
    @Prop(Array)
    private assets!: Release.Asset[]

    get playUrl(): string {
      return this.$env.GooglePlay
    }

    get groupedAssets() {
        const orderP = ['win32', 'darwin', 'linux', 'android', 'ios']
        const orderA = ['x86', 'arm64', 'universal']

        let result: Release.Asset[][] = []
        orderP.forEach(item => {
            result = [
                ...result,
                this.assets
                    .filter(asset => {
                        return asset.platform === item
                    })
                    .sort((ra, rb) => {
                        return orderA.indexOf(ra.arch) - orderA.indexOf(rb.arch)
                    })
            ]
        })

        return result
    }
}
