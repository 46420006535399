
















import { Vue, Prop, Component } from 'vue-property-decorator'

@Component
export default class Mobile extends Vue {
  @Prop(String)
  private androidUrl!: string
  
  get playUrl(): string {
    return this.$env.GooglePlay
  }

  get appUrl(): string {
    return this.$env.AppStore
  }
}
